.postWrapper {
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 20px;
  background: #fff;
}
.postHeader {
  padding: 10px 10px;
}
.postAvatar {
  display: flex;
}
.postAvatar > div {
  display: flex;
  flex-direction: column;
  padding-left: 12px;
}
.postAvatar img {
  height: 40px;
}
.postAvatar .postAuthor {
  color: #616161;
  /* font-family: Open Sans; */
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}
.postAvatar .postAuthor:hover {
  text-decoration: underline;
}
.postAvatar .postTime {
  /* font-family: Open Sans; */
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #9e9e9e;
}
.postContent {
  /* font-family: Open Sans; */
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #424242;
  margin-top: 15px;
}
.postActions {
  padding: 5px;
  display: flex;
  border: 1px solid #e0e0e0;
  padding: 5px 10px;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #616161;
  border-right: none;
  border-left: none;
}
.postActions img {
  height: 18px;
}
.postActions img:hover {
  cursor: pointer;
}
.postLike {
  display: flex;
  align-items: center;
}
/*COMMENT*/
.deleteComment img {
  height: 20px;
}
.deleteComment {
  padding: 0;
  margin: 0;
  margin-top: 7px;
  touch-action: manipulation;
  cursor: pointer;

  height: 18px;
}
.postCommentsIcon {
  margin-left: 15px;
  display: flex;
  align-items: center;
}
.postComment {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.postLike span,
.postCommentsIcon span {
  margin-left: 7px;
}
.postCommentBox {
  padding: 10px 10px;
}
.postCommentBox input {
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  height: 35px;
  margin: 0 auto;
  width: 100%;
  font-size: 15px;
  box-sizing: border-box;
  padding: 5px;
}
.postCommentBox input:focus {
  outline: none;
}
.postCommentsList {
  padding: 5px 10px;
  position: relative;
}
.postCommentItem {
  background: #eceffa;
  border-radius: 4px;
  padding: 10px;
  margin-bottom: 14px;
}
.postCommentHeader {
  display: flex;
}
.postCommentHeader .postCommentAuthor {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #757575;
}
.postCommentHeader .postCommentTime {
  font-size: 12px;
  line-height: 16px;
  color: #9e9e9e;
  margin-left: 8px;
}
.postCommentHeader .postCommentLikes {
  font-size: 12px;
  line-height: 16px;
  color: #616161;
  margin-left: 8px;
}
.postCommentContent {
  margin-top: 7px;
}
.postCommentsList .commentLike {
  position: absolute;
  right: 21px;
  top: 13px;
}
.postCommentsList .commentLike img {
  height: 20px;
}
.postCommentsList .commentLike img:hover {
  cursor: pointer;
}

/* Styles added */
.home {
  padding: 20px 50px;
  display: flex;
  width: 80%;
  margin: 0 auto;
}
.home .posts-list {
  flex-grow: 0.8;
}
.friendsList {
  background: #fff;
  margin-left: 50px;
  flex-grow: 0.2;
  height: 50vh;
  box-sizing: border-box;
  margin-top: 20px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  overflow-y: scroll;
}
.friendsList .header {
  border-bottom: 1px solid #e0e0e0;
  font-weight: 600;
  font-size: 16px;
  color: #424242;
  padding: 20px;
}
.friendsList .friendsImg img {
  height: 40px;
}
.noFriends {
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.friendsItem {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  color: #616161;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-decoration: none;
}
.friendsItem:hover {
  cursor: pointer;
  background-color: #f1f1f1;
}
.friendsName {
  padding: 0 10px;
}
.postsList {
  flex: 1;
}
.friendsList::-webkit-scrollbar {
  display: none; /* for Chrome, Safari, and Opera */
}
/* Create Post */
.createPost {
  background: white;
  border: 1px solid #e0e0e0;
  box-sizing: border-box;
  border-radius: 4px;
}
.createPost > div {
  padding: 10px;
  border-top: 1px solid #d0d0d0;
  display: flex;
  justify-content: flex-end;
}
.createPost textarea {
  height: 90px;
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  font-size: 15px;
  border: none;
  resize: none;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
}
.addPostBtn {
  width: 112px;
  height: 42px;
  left: 111.5px;
  top: 0px;
  background: #ef7917;
  border-radius: 3px;
  color: #fff;
  font-size: 16px;
  border: none;
}
.addPostBtn:focus,
.createPost textarea:focus {
  outline: none;
}
.addPostBtn:hover {
  cursor: pointer;
}
/**Create Post*/
.post {
  width: 100%;
  height: 10%;
  border: 0;
  margin: 0;
  padding: 0;
}
.post textarea {
  width: 100%;
  height: 40%;
  border: 1px solid black;
  border-radius: 6px;
  padding: 14px;
  font: inherit;
  outline: none;
}

.post button {
  float: right;
  color: white;
  background-color: #8e4ceb;
  border: 1px solid;
  border-radius: 4px;
  padding: 4px;
}
.postLikesBtn {
  padding: 0;
  border: none;
  background-color: red;
}
.postDislikesBtn {
  padding: 0;
  border: none;
  background-color: white;
}
